import styled from 'styled-components';

const P = styled.p`
  color: #252525; //#666d71
  display: block;
  font-size: 1.8em;
  margin: 50px 30px 0;
  text-align: center;
  font-family: Inter,sans-serif;
  line-height: 25px;

`;

export default P;