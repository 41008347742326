import styled from 'styled-components';

const Cursor = styled.span`
color: #0f2b3d;
font-size: 1.8em;
font-weight:900;
text-align: center;
font-family: Inter,sans-serif;
line-height: 16px;

`;

export default Cursor;